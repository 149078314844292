import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { scurl } from '../../core/utils.js';
import isScreen from '../../core/screenHelper';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup,
} from 'reactstrap';

import Widget from '../../components/Widget/Widget';

import Poster from '../../components/Poster';

import WidgetTransactionField from '../../widgets/transactions/Field';
import WidgetFunnel from '../../widgets/conversions/Funnel';
import WidgetGrosses from '../../widgets/industry/Grosses';
import WidgetSalesTimespans from '../../widgets/transactions/Timespans';
import WidgetLeads from '../../widgets/transactions/Leads';
import WidgetPrices from '../../widgets/transactions/Prices';
import WidgetMargin from '../../widgets/transactions/Margin';
import WidgetShare from '../../widgets/universal/Share';
import WidgetSearch from '../../widgets/search/Pipeline';

class Product extends React.Component {

  constructor(props) {
    super(props);

    let tabSelected = 0;
    let perf_date = false;

    let q = props.location.state;

    if(q && q.tab) {
      tabSelected = q.tab;
    } else {
      if(this.props.match.params.tab) {
        tabSelected = parseInt(this.props.match.params.tab);
      }
    }

    if(q && q.perf_date) {
      perf_date = q.perf_date;
    }

    var tabs = [
      {
        id: 0,
        icon: 'fa-circle-dollar-to-slot',
        name: 'Summary'
      },
      {
        id: 1,
        icon: 'fa-users-gear',
        name: 'Details'
      }
    ];

    let baseUrl = '/#/app/products/' + this.props.match.params.id + '/';

    this.state = {
      isLoaded: false,
      tabSelected: tabSelected,
      tabs: tabs,
      lead_groups: [
        '0-0,1-3,4-6,7-13,14-20,21-27,28-34,35-41,42-48,49-55,56-62,63-69,70-76,77-83,84-1000',
        '0-28,29-55,56-1000'
      ],
      price_groups: [
        '0-59.99,60-79.99,80-99.99,100-129.99,130-159.99,160-189.99,190-229.00,230-999.99'
      ],
      perf_date: perf_date,
      base_url: baseUrl
    };

    this.setEngagement = this.setEngagement.bind(this);
    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setEngagement(this.props.match.params.id);
    this.setTabs();
  };

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  setEngagement = (id) => {

    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/products/' + id,
        type: 'simple'
      };

      scurl(opts, function(err, results) {
        if(err) {
          this.setState({
            error: {
              json: err,
              status: err.status || -99,
              message: err.message || 'Error occured'
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            results: results,
          });
        }
      }.bind(this));
    }); 

  }

  setTabs = () => {

    let tabs = [];

    if(this.props.user.permissions.pages.product.tabs.timespans) {
      tabs.push({
        id: 0,
        icon: 'fa-circle-dollar-to-slot',
        name: 'Summary'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.detail && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 1,
        icon: 'fa-users-gear',
        name: 'Details'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.leads && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 2,
        icon: 'fa-calendar-range',
        name: 'Lead Times'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.margins && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 3,
        icon: 'fa-bullseye',
        name: 'Margins'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.funnel && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 4,
        icon: 'fa-bullseye',
        name: 'Funnel'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.share && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 5,
        icon: 'fa-chart-pie',
        name: 'Market Share'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.search && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 8,
        icon: 'fa-search',
        name: 'Search'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.ga && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 6,
        icon: 'fa-chart-line',
        name: 'GA4'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.prices && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 7,
        icon: 'fa-dollar',
        name: 'Price Points'
      });
    }
    if(this.props.user.permissions.pages.product.tabs.detail && !this.props.filter.limit.chargeback) {
      tabs.push({
        id: 9,
        icon: 'fa-user',
        name: 'Acxiom'
      });
    }
    
    this.setState({
      tabs: tabs
    });

  }

  render() {

    return (
      
      <div>

        {this.state && this.state.isLoaded ? (
          <div>
            
            {this.state.results.product ? (
              <div>

                <Helmet>
                  <title>SH4: {this.state.results.product.short_name || this.state.results.product.name}</title>
                </Helmet>

                <div className="menuContainer lg">
                  <Nav>
                    <NavItem className="nopad-bottom nomargin-bottom">
                      {this.state.results.product.poster_id ? (
                        <Poster id={this.state.results.product.poster_id} w={75} />
                      ) : (
                        <Poster w={75} />
                      )}
                    </NavItem>
                    <NavItem className="nopad-bottom nomargin-bottom gutter-left-fixed-2">
                      <h4 className="page-title">
                        {this.state.results.product.short_name || this.state.results.product.name}<br/>
                        {this.state.results.product.venue ? (
                          <small className="text-muted">{this.state.results.product.venue.name}</small>
                        ) : null}
                      </h4>
                      <ButtonGroup>
                        {this.state.tabs.map((tab, i) =>
                          <a key={i} href={this.state.base_url + tab.id} className="">
                            <Button
                              color={this.state.tabSelected === tab.id ? 'danger' : 'default'} className="mr-xs"
                              active={this.state.tabSelected === tab.id}
                            ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === tab.id ? 'text-default' : 'text-danger'].join(' ')} />
                            <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                          </a>
                        )}
                      </ButtonGroup>
                    </NavItem>
                  </Nav>
                </div>

                <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

                {this.state.results.product ? (
                  <div>

                    {this.state.tabSelected === -1 ? (
                      <div className="m-4 p-4">
                        Please select tab above
                      </div>
                    ): null}

                    {this.state.tabSelected === 0 ? (
                      <WidgetSalesTimespans product_id={this.state.results.product.id} affiliate={false} perf_date={this.state.perf_date} user={this.props.user} />
                    ): null}

                    {this.state.tabSelected === 1 ? (
                      <div>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <WidgetTransactionField field="$sale_or_refund" title="Sales/Refunds" field_header="Sales Type" sales_metric="face" share_to_total={false} product_id={this.state.results.product.id}  />
                            <WidgetTransactionField field="$customer.dma" title="DMA" field_header="DMA" className="gutter-top-fixed-2" product_id={this.state.results.product.id} refunds={0} />
                            <WidgetTransactionField field="$customer.country" title="Country" field_header="Country" className="gutter-top-fixed-2" product_id={this.state.results.product.id} refunds={0} />
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <WidgetTransactionField field="$wallet" title="Wallets" field_header="Wallet" share_to_total={true} product_id={this.state.results.product.id} refunds={0} />
                            <WidgetTransactionField field="$quantity" title="Per Order QTY" field_header="QTY" className="gutter-top-fixed-2" product_id={this.state.results.product.id} refunds={0} />
                            <WidgetTransactionField field="$ticket_price" title="Ticket Price" field_header="Price" className="mt-3 mb-3" product_id={this.state.results.product.id} sales_metric="qty" refunds={0} />
                          </Col>
                        </Row>
                      </div>
                    ): null}

                    {this.state.tabSelected === 2 ? (
                      <div>
                        <WidgetLeads product_id={this.state.results.product.id} affiliate={false} lead_groups={this.state.lead_groups[0]} />
                      </div>
                    ): null}

                    {this.state.tabSelected === 3 ? (
                      <div>
                        <WidgetMargin product_id={this.state.results.product.id} />
                      </div>
                    ): null}

                    {this.state.tabSelected === 4 ? (
                      <div>
                        <WidgetFunnel product_id={this.state.results.product.id} />
                      </div>
                    ): null}

                    {this.state.tabSelected === 5 ? (
                      <div>
                        <WidgetGrosses product_id={this.state.results.product.id} />
                      </div>
                    ): null}

                    {this.state.tabSelected === 6 ? (
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <WidgetShare product_id={this.state.results.product.id} className="mb-3" print={false} title="Traffic Medium" ga_filter={this.state.ga_filter_string} ga_property="source.medium" description="GA4 transaction data" />
                          <WidgetShare product_id={this.state.results.product.id} className="mb-3" print={false} title="New or Returning" ga_filter={this.state.ga_filter_string} ga_property="session.type" description="GA4 transaction data" />
                          <WidgetShare product_id={this.state.results.product.id} className="mb-3" print={false} title="Country" ga_filter={this.state.ga_filter_string} ga_property="geo.country" description="GA4 transaction data" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <WidgetShare product_id={this.state.results.product.id} className="mb-3" print={false} title="Traffic Source" ga_filter={this.state.ga_filter_string} ga_property="source.source" description="GA4 transaction data" />
                          <WidgetShare product_id={this.state.results.product.id} className="mb-3" print={false} title="Device Type" ga_filter={this.state.ga_filter_string} ga_property="device.type" description="GA4 transaction data" />
                        </Col>
                      </Row>
                    ): null}

                    {this.state.tabSelected === 7 ? (
                      <div>
                        <WidgetPrices product_id={this.state.results.product.id} affiliate={false} price_groups={this.state.price_groups[0]} />
                      </div>
                    ): null}

                    {this.state.tabSelected === 8 ? (
                      <div>
                        <WidgetSearch product_id={this.state.results.product.id} />
                      </div>
                    ): null}

                    {this.state.tabSelected === 9 ? (
                      <div>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <WidgetTransactionField product_id={this.state.results.product.id} className="mb-3" field="$acxiom.gender" title="Male/Female" field_header="Gender" sales_metric="qty" affiliate={false} ignore_null={true} fixed_years={1} />
                              <WidgetTransactionField product_id={this.state.results.product.id} className="mb-3" field="$acxiom.household_income.range" title="Household Income" field_header="Ranges" sales_metric="qty" affiliate={false} ignore_null={true} fixed_years={1} />
                              <WidgetTransactionField product_id={this.state.results.product.id} className="mb-3" field="$acxiom.net_worth.range" title="Net Worth" field_header="Ranges" sales_metric="qty" affiliate={false} ignore_null={true} fixed_years={1} />
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <WidgetTransactionField product_id={this.state.results.product.id} className="mb-3" field="$acxiom.marital.status" title="Marital Status" field_header="Status" sales_metric="qty" affiliate={false} ignore_null={true} fixed_years={1} />
                              <WidgetTransactionField product_id={this.state.results.product.id} className="mb-3" field="$acxiom.children.count" title="Number of Children" field_header="Count" sales_metric="qty" affiliate={false} ignore_null={true} exclude_zero={false} fixed_years={1} />
                              <WidgetTransactionField product_id={this.state.results.product.id} className="mb-3" field="$acxiom.education" title="Education" field_header="Highest Known Completed" sales_metric="qty" affiliate={false} ignore_null={true} exclude_zero={false} fixed_years={1} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ): null}

                  </div>
                ) : null}
                 </div>
              </div>
            ) : (
              this.state.error ? (
                <Widget>
                  <h5 className="page-title text-danger">
                    Show failed to load<br/>
                    <br/><small className="text-muted">{this.state.error.message}</small>
                  </h5>
                </Widget>
              ) : null
            )}
          </div>
          
        ) : (
          <p>Loading...</p>
        )}

      </div>
    );

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Product));
