import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Table,
  Button
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Displays from '../../../components/Displays';
import Loading from '../../../components/Loading';
import { scurl, checkExists, filterUpdated, changeSalesMetric, changeAffiliateSalesMetric, displaySalesMetric, displayMetricFormat } from '../../../core/utils';

class WidgetTransactionField extends React.Component {

  static propTypes = {
    show_code: PropTypes.string,
    print: PropTypes.bool,
    field: PropTypes.string,
    field_header: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    perf_date: PropTypes.bool,
    sales_metric: PropTypes.string,
    className: PropTypes.string,
    display_length: PropTypes.number,
    share_to_total: PropTypes.bool,
    refunds: PropTypes.number,
    product_id: PropTypes.number,
    affiliate: PropTypes.bool,
    fixed_years: PropTypes.number
  };

  static defaultProps = {
    show_code: '',
    print: false,
    field: null,
    field_header: 'Field',
    title: '',
    perf_date: false,
    sales_metric: 'face',
    className: '',
    display_length: 5,
    share_to_total: false,
    refunds: -1,
    affiliate: true,
    fixed_years: 2,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      display_length: this.props.display_length,
      perf_date: this.props.perf_date,
      sales_metric: this.props.sales_metric,
      refunds: this.props.refunds
    }

    this._isMounted = false;
    this.loadSales = this.loadSales.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadSales();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'FIELD WIDGET')) {
      this.loadSales();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadSales() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/transactions/field',
        type: 'universal',
        rollup: 0,
        years: this.props.fixed_years
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      if(this.props.field) {
        opts.field = this.props.field;
      }

      if(typeof this.state.refunds !== 'undefined') {
        opts.refunds = this.state.refunds;
      }

      opts.perf_date = this.state.perf_date;
      opts.exclude_zero = this.props.exclude_zero;
      opts.ignore_null = this.props.ignore_null;

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });  
  }

  viewAll = () => { 
    this.setState({
      display_length: this.state.results.blocks.length
    });
  }

  changePerfTrans = () => { 
    this.setState({
      perf_date: !this.state.perf_date
    }, function() {
      this.loadSales();
    });
  }

  changeSalesMetric = () => { 
    var change_display = '';
    if(this.props.affiliate) {
      change_display = changeAffiliateSalesMetric(this.state.sales_metric);
    } else {
      change_display = changeSalesMetric(this.state.sales_metric);
    }
    this.setState({
      sales_metric: change_display
    });
  };

  render() {

    var title = <h5><span className="text-danger">{this.props.title}</span>&nbsp;&nbsp;{this.props.description ? (<small className="text-muted">{this.props.description}</small>) : (<small className="text-muted"><button className="header-link" onClick={() => this.changeSalesMetric()}>{displaySalesMetric(this.state.sales_metric)}</button> by <button className="header-link" onClick={() => this.changePerfTrans()}>{this.state.perf_date ? (<span>Performance Date</span>) : (<span>Transaction Date</span>)}</button>. {this.state.refunds === 0 ? (<span>Excludes refunds.</span>) : null}</small>)}</h5>;

    return (

      <div>

        <Widget title={title} className={this.props.className}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div>
              <Table className="gutter-top-fixed-2">
                <thead>
                  <tr>
                    <th width="40%">{this.props.field_header}</th>
                    {!this.props.print ? (
                      <th width="20%" className="text-center">{displaySalesMetric(this.state.sales_metric)}</th>
                    ) : null }
                    <th width="20%" className="text-center">Share</th>
                    <th width="20%" className="text-center">vs PY</th>
                  </tr>
                </thead>
                <tbody> 
                    {this.state.results.blocks[0] ? (
                      this.state.results.blocks.map((block, i) =>
                        i < this.state.display_length && checkExists(block.field) && (block.field !== null) ? (
                          <tr key={i}>
                            <td>
                              <span className={'text-capitalize ' + (this.props.print ? 'fs-mini' : null)}>{block.field}</span>
                            </td>
                            {!this.props.print ? (
                              <td className="text-center">
                                <span className={this.props.print ? 'fs-mini' : null}>{displayMetricFormat(this.state.sales_metric,block.years[0][this.state.sales_metric])}</span>
                              </td>
                            ) : null }
                            <td className="text-center">
                              <span className={this.props.print ? 'fs-mini' : null}>{parseFloat(block.years[0].share[this.state.sales_metric]*100).toFixed(1)}%</span>
                            </td>
                            <td className="text-center">
                              <span className={this.props.print ? 'fs-mini' : null}>
                                {block.years[1] && block.years[1].qty > 0 ? (
                                  <span><Displays a={block.years[0][this.state.sales_metric]} b={block.years[1][this.state.sales_metric]} format="percent" /></span>
                                ) : (
                                  <span>--</span>
                                )}
                              </span>
                            </td>
                          </tr>
                        ) : null
                      )
                    ) : null}
                </tbody>
              </Table>

              {(this.state.display_length < this.state.results.blocks.length) && !this.props.print ? (
                <div className="text-center d-print-none">
                  <Button className="btn-success" onClick={() => this.viewAll()}><i className="fa-light fa-arrows-up-down fa-lg gutter-right-fixed-1" />View All</Button>
                </div>
              ) : null}
            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetTransactionField));
