import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';


import WidgetExcel from '../../widgets/downloads/Excel';
import WidgetBwYears from '../../widgets/bw/Years';
import WidgetBwShare from '../../widgets/bw/Share';
import WidgetBwMargins from '../../widgets/bw/Margins';
import WidgetBwShows from '../../widgets/bw/Shows';
import WidgetSpacer from '../../widgets/utils/Spacer';

import s from './Print.module.scss';

import { filterUpdated } from '../../core/utils';

class Print extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };

    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'PRINT PAGE')) {
      this.setTabs();
    }
  }

  setTabs(){

      var tabSelected = 0;
      var tabs = [
        {
          id: 0,
          icon: 'fa-file-excel',
          name: 'Downloads'
        },
        {
          id: 1,
          icon: 'fa-calendar',
          name: 'Years'
        },
        {
          id: 2,
          icon: 'fa-star',
          name: 'Shows'
        },
        {
          id: 3,
          icon: 'fa-chart-pie-simple',
          name: 'Share'
        },
        {
          id: 4,
          icon: 'fa-flashlight',
          name: 'Margins'
        }
      ];
      
      this.setState({
        tabs: tabs,
        tabSelected: tabSelected
      });

  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  render() {
  
    return (
      <div className={s.root}>

        <Helmet>
          <title>SH4: Printable Reports</title>
        </Helmet>

        <div className="menuContainer lg">
          <Nav className="gutter-top-fixed-2">
            <NavItem className="nopad-bottom nomargin-bottom">
                <h5 className="text-danger">Printable Reports</h5>
                {this.state.tabs ? (
                <ButtonGroup>
                  {this.state.tabs.map((tab, i) =>
                    <Button key={i}
                      color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                      active={this.state.tabSelected === i}
                    ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                    <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                  )}
                </ButtonGroup>
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className="m-2">

          {this.state.tabSelected === 0 ? (
            <div className="gutter-top-fixed-2">
              <WidgetExcel print={true} />
            </div>
          ): null}       

          {this.state.tabSelected === 1 ? (
            <div>
              <WidgetBwYears print={true} title='Transaction Date' fixed_years={6} />
              <div className="page-divide"></div>
              <WidgetBwYears print={true} perf_date={true} title='Performance Date' fixed_years={6} />
              <WidgetSpacer print={true} />
            </div>
          ): null}

          {this.state.tabSelected === 2 ? (
            <div>
              <WidgetBwShows print={true} broadway={true} perf_date={false} sales_metric="face" affiliate={false} display={45} />

              <div className="page-divide"></div>

              <WidgetBwShows print={true} broadway={true} perf_date={true} sales_metric="face" affiliate={false} display={45} />

            </div>
          ): null}

          {this.state.tabSelected === 3 ? (
            <div>

              <WidgetBwShare print={true} />
              <WidgetSpacer print={true} />
            </div>
          ): null}

          {this.state.tabSelected === 4 ? (
            <div>

              <WidgetBwMargins print={true} />

              <div className="page-divide"></div>

            </div>
          ): null}


        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Print));
