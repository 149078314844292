import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Nav,
  NavItem,
  Button,
  Row,
  Col
} from 'reactstrap';

import WidgetTimespans from '../../widgets/transactions/Timespans';
import WidgetShows from '../../widgets/known/Shows';
import WidgetPipeline from '../../widgets/search/Pipeline';
import WidgetCampaigns from '../../widgets/search/Campaigns';
import WidgetDownload from '../../widgets/known/Download';
import WidgetTransactionField from '../../widgets/known/Field';

import isScreen from '../../core/screenHelper';

import s from './Known.module.scss';

class Known extends React.Component {

  constructor(props) {
    super(props);

    let perf_date = false;
    
    let tabSelected = 0;
    let q = props.location.state;
    if(q && q.tab) { 
      tabSelected = q.tab; 
    } else {

      if(this.props.match.params.tab) {
        tabSelected = parseInt(this.props.match.params.tab);
      }
    }
    if(q && q.perf_date) { perf_date = q.perf_date; }

    const url = new URL(window.location.href);
    let baseUrl = '/#/app/known/';
    let baseTitle = 'Known';
    if(url.hash.includes('dashboard')) {
      baseUrl = '/#/app/dashboard/';
      baseTitle = 'Known Dashboard';
    }

    this.state = {
      isLoaded: false,
      tabSelected: tabSelected,
      perf_date: perf_date,
      base_url: baseUrl,
      title: baseTitle
    };

    this.setTabs = this.setTabs.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs() {

    let tabs = [];

    tabs.push({
      id: 0,
      icon: 'fa-ticket',
      name: 'Sales<br/>Summary'
    });
    tabs.push({
      id: 1,
      icon: 'fa-scale-balanced',
      name: 'Show<br/>Metrics'
    });
    tabs.push({
      id: 2,
      icon: 'fa-search',
      name: 'Search<br/>KPIs'
    });
    tabs.push({
      id: 3,
      icon: 'fa-megaphone',
      name: 'Generic<br/>Campaigns'
    });
    tabs.push({
      id: 4,
      icon: 'fa-megaphone',
      name: 'Show<br/>Campaigns'
    });
    tabs.push({
      id: 5,
      icon: 'fa-light fa-download',
      name: 'Data<br/>Access'
    });
    tabs.push({
      id: 6,
      icon: 'fa-light fa-users',
      name: 'By<br/>DMA'
    });
    
    this.setState({
      tabs: tabs
    });

  }

  render() {
  
    return (
      <div className={s.root}>

        {this.state ? (
          <Helmet>
            <title>SH4: {this.state.title}</title>
          </Helmet>
        ) : null}
        
        <div className="menuContainer lg">
          <Nav className="mt-4">
            <NavItem className="nopad-bottom nomargin-bottom">
                <h5 className="text-danger">Known Dashboard</h5>
                {this.state.tabs ? (
                  this.state.tabs.map((tab, i) =>
                    <a key={i} href={this.state.base_url + tab.id}>
                      <Button
                        color={this.state.tabSelected === tab.id ? 'danger' : 'default'} className="mr-xs text-center"
                        active={this.state.tabSelected === tab.id}
                      >
                        <i className={['fa-light','fa-lg','d-lg-none','d-xl-none',tab.icon,this.state.tabSelected === tab.id ? 'text-default' : 'text-danger'].join(' ')} />
                        <span className="d-md-down-none" dangerouslySetInnerHTML={{__html: tab.name}}></span>
                      </Button>
                    </a>
                  )
                ) : null}
            </NavItem>
          </Nav>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === 0 ? (
            <>
              <WidgetTimespans affiliate={false} perf_date={this.state.perf_date} fixed_years={2} refunds={false} limit_sources="BROADWAY.COM" user={this.props.user} />
            </>
          ): null}

          {this.state.tabSelected === 1 ? (
            <>
              <WidgetShows sales_metric="fees" affiliate={false} display={1000} refunds={false} limit_sources="BROADWAY.COM" user={this.props.user} />
            </>
          ): null}

          {this.state.tabSelected === 2 ? (
            <>
              <WidgetPipeline />
            </>
          ): null}

          {this.state.tabSelected === 3 ? (
            <>
              <WidgetCampaigns print={false} skai_filter="" user={this.props.user} type="generic" />
            </>
          ): null}

          {this.state.tabSelected === 4 ? (
            <>
              <WidgetCampaigns print={false} skai_filter="" user={this.props.user} type="shows" />
            </>
          ): null}

          {this.state.tabSelected === 5 ? (
            <>
              <WidgetDownload />  
            </>
          ): null}

          {this.state.tabSelected === 6 ? (
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <div>
                  <WidgetTransactionField field="$customer.dma" title="DMA" field_header="DMA" sales_metric="qty" affiliate={false} refunds={0} display_length={25} />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <div>
                  <WidgetTransactionField field="$customer.country" title="Country" field_header="Country" sales_metric="qty" affiliate={false} refunds={0} display_length={25} />
                </div>
              </Col>
            </Row>
          ): null}
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Known));
